import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Footer from '../features/Footer'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Logo from '../components/Logo'
import Text, {
  TEXT_TYPE,
  TEXT_WEIGHT,
  OrderedList,
  ListItem,
  MARKER_TYPE,
} from '../components/Typography'
import { StyledA, StyledLink } from '../components/Link'

const TextWrapper = styled.section`
  width: 50%;
  margin: 0 auto;
`

const TextSection = styled.section`
  margin-bottom: 25px;
`

const InlineParagraph = styled(Text)`
  display: inline-block;
`

const PrivacyPolicy = ({ data }) => (
  <Layout>
    <Seo
      title={data.site.siteMetadata.title}
      description={data.site.siteMetadata.description}
    />
    <Logo />
    <TextWrapper>
      <TextSection>
        <p>
          <StyledLink to="/">Back to home</StyledLink>
        </p>
        <Text textType={TEXT_TYPE.H1}>Privacy policy</Text>
        <Text>Last updated on 29 May 2019</Text>
      </TextSection>
      <TextSection>
        <Text>
          This privacy policy (hereinafter –{' '}
          <InlineParagraph weight={TEXT_WEIGHT.BOLD}>“Policy”</InlineParagraph>)
          describes and provides information on how Family Friendly DNS LLC
          (hereinafter –{' '}
          <InlineParagraph weight={TEXT_WEIGHT.BOLD}>
            “FAMILY DNS”
          </InlineParagraph>
          “FAMILY DNS”,{' '}
          <InlineParagraph weight={TEXT_WEIGHT.BOLD}>“we”</InlineParagraph>, or{' '}
          <InlineParagraph weight={TEXT_WEIGHT.BOLD}>“us”</InlineParagraph>)
          gathers your personal data while you are using the Family Friendly DNS
          application (hereinafter –{' '}
          <InlineParagraph weight={TEXT_WEIGHT.BOLD}>
            “Application”
          </InlineParagraph>{' '}
          or <InlineParagraph weight={TEXT_WEIGHT.BOLD}>“App”</InlineParagraph>)
          as well as on how such data may be used. This Policy also describes
          the rights and choices available to you in regards to the information
          collected.
        </Text>
      </TextSection>
      <TextSection>
        <Text>
          We may amend and/or update this Policy from time to time. We will
          notify you by a pop-up on the application or by other means deemed fit
          in advance of any considerable changes being made to this Policy.
        </Text>
      </TextSection>
      <TextSection>
        <Text>
          In case of any questions related to this Policy or means of collecting
          your personal information, please contact us at:{' '}
          <StyledA href="mailto:familyfriendlydns@gmail.com">
            familyfriendlydns@gmail.com
          </StyledA>
          .
        </Text>
      </TextSection>
      <TextSection>
        <Text textType={TEXT_TYPE.H2}>APPLICATION USERS’ DATA</Text>
        <Text textType={TEXT_TYPE.H3}>THE APPLICATION</Text>
        <TextSection>
          <Text>
            The Family DNS App is an DNS changer application allowing its users
            to filter out and block hazardous, malicious and corrupting internet
            websites. The DNS servers used within the Application are controlled
            and provided by third-party DNS providers, while the Application
            acts as a tool allowing you to set up and make use of such DNS
            servers in a simple and swift way.
          </Text>
        </TextSection>
        <Text>
          For the purpose of providing you with access to the Application and
          ensuring its intended performance, we must collect a limited amount of
          your personal data related to you and your device:
        </Text>
        <OrderedList markerType={MARKER_TYPE.UPPERCASE_ROMANIAN}>
          <ListItem>The type of operating system used;</ListItem>
          <ListItem>Device’s charging state;</ListItem>
          <ListItem>Device’s battery status;</ListItem>
          <ListItem>
            Network information regarding the type of connection used on the
            device (ethernet, wi-fi, etc.);
          </ListItem>
          <ListItem>Your IP address.</ListItem>
        </OrderedList>
        <TextSection>
          <Text>
            The personal information listed above is collected solely on the
            basis of your explicit consent, given to us by you accessing and/or
            using the Application. The information collected is limited to what
            is strictly necessary for the proper functioning of the Application
            and does not include any sensitive personal information.
          </Text>
        </TextSection>
        <TextSection>
          <Text>
            In case of your communication with us via e-mail, we will also
            collect your e-mail details and the information within such
            correspondence in order to provide you with a response and
            assistance with any issue that you may have. The collection of such
            information is based on our legitimate interest of ensuring proper
            and satisfactory communication and user support to the Application’s
            users.
          </Text>
        </TextSection>
        <TextSection>
          <Text textType={TEXT_TYPE.H3}>WEBSITE</Text>
        </TextSection>
        <TextSection>
          <Text>
            When you download the Application through its dedicated website we
            automatically collect certain information about you via Google
            analytics. The basis of such data processing is based on our
            legitimate interest of analysing and improving our services.
          </Text>
        </TextSection>
        <TextSection>
          <Text textType={TEXT_TYPE.H3}>SDK</Text>
        </TextSection>
        <TextSection>
          <Text>
            We are working hard to keep the Application low cost or free to all
            users. For this end, we incorporate trusted Globalhop Ltd SDK into
            the Application. This SDK allows us to commercialize your use of the
            Application by providing us access to your device and using a little
            amount of your network traffic and device resources, thus bringing
            benefit to all parties involved. For more information about
            Globalhop Ltd SDK and personal information it may collect, please
            consult{' '}
            <StyledA
              href="https://globalhop.net/privacy-policy/"
              rel="noopener noreferrer"
              target="blank"
            >
              Globalhop Ltd Privacy Policy
            </StyledA>
            .
          </Text>
        </TextSection>
        <TextSection>
          <Text textType={TEXT_TYPE.H2}>DATA SHARING</Text>
        </TextSection>
        <TextSection>
          <Text>
            In order to be able to deliver our services, we use data storage
            provided to us by our service providers (data processors). Such
            providers are obligated to keep your data secure, not disclose your
            information to any third parties and do not have the right to access
            your data for any purpose bar data storage.
          </Text>
        </TextSection>
        <TextSection>
          <Text>
            Other than our data processing service providers, we will not share,
            disclose and/or entrust your personal data to any third-party except
            if:
          </Text>
        </TextSection>
        <TextSection>
          <OrderedList markerType={MARKER_TYPE.UPPERCASE_ROMANIAN}>
            <ListItem>
              The disclosure of your personal data is requested by law in order
              to comply with an order of a court, to enforce our rights under an
              agreement between as, otherwise protect the rights of our company
              or its stakeholders.
            </ListItem>
            <ListItem>
              We receive your request or authorization to do so.
            </ListItem>
            <ListItem>
              Such disclosure is necessary for our agents to function on our
              behalf.
            </ListItem>
            <ListItem>It is necessary to address and emergency.</ListItem>
          </OrderedList>
        </TextSection>
        <TextSection>
          <Text textType={TEXT_TYPE.H2}>DATA STORAGE AND SECURITY</Text>
        </TextSection>
        <TextSection>
          <Text>
            The personal data we process as a result of your use of the
            Application will not be stored for a longer time period than
            necessary in order to provide you the Application’s services.
          </Text>
        </TextSection>
        <TextSection>
          <Text>
            All your personal data that we collect and/or process is subject to
            high level of care on our part. For this purpose, we take measures,
            such as updates, testing and/or restriction of access, in order to
            ensure that your information is being kept in a lawful manner and
            with a high level of care.{' '}
          </Text>
        </TextSection>
        <TextSection>
          <Text textType={TEXT_TYPE.H2}>YOUR PERSONAL DATA RIGHTS</Text>
        </TextSection>
        <TextSection>
          <Text>
            You may choose to exercise any of the following rights related to
            your personal data by contacting us at{' '}
            <StyledA href="mailto:familyfriendlydns@gmail.com">
              familyfriendlydns@gmail.com
            </StyledA>
            :
          </Text>
        </TextSection>
        <TextSection>
          <OrderedList markerType={MARKER_TYPE.UPPERCASE_ROMANIAN}>
            <ListItem>
              Right to delete. You have the right to request us to delete the
              personal data that we have collected about you. We will satisfy
              such request if: (a) at the time of the request, the data is not
              needed for the purposes it was collected for; (b) the data is
              necessary for us in order to comply with a legal obligation or an
              establishment, exercise or defence of a legal claim.
            </ListItem>
            <ListItem>
              Right to access. You may request us to provide access to your
              personal data collected by us. Since we collect a limited amount
              of your data, we may request to receive additional information to
              be able to identify you, enabling us to satisfy your request. Such
              information would be used for the sole purpose of helping exercise
              this right.
            </ListItem>
            <ListItem>
              Right to amend. You can request us to edit any outdated or
              misleading information about you.
            </ListItem>
          </OrderedList>
        </TextSection>
        <TextSection>
          <Text>
            If you are based in EEA, you are also entitled the following rights
            under the General Data Protection Regulation:
          </Text>
        </TextSection>
        <OrderedList markerType={MARKER_TYPE.UPPERCASE_ROMANIAN}>
          <ListItem>Receiving your data in an accessible format;</ListItem>
          <ListItem>
            Ask to transfer your data to another data controller;
          </ListItem>
          <ListItem>
            Limit the unlawful processing of your personal data;
          </ListItem>
          <ListItem>
            File a complaint regarding our use of your personal data to local
            supervisory authorities;
          </ListItem>
          <ListItem>
            Where data processing is based on our legitimate interests, you can
            object to us processing your data if in doing so we breach your
            fundamental rights and freedoms.
          </ListItem>
        </OrderedList>
        <TextSection>
          <Text textType={TEXT_TYPE.H2}>INFORMATION OF CHILDREN</Text>
        </TextSection>
        <TextSection>
          <Text>
            We do not knowingly attempt to solicit or receive information from
            children under the age of 14 or any other age requiring consent of a
            child’s parent or legal guardian. If we process any personal
            information of a child by accident, we will delete and take all
            measures necessary to dispose of such information as soon as we
            become aware of such situation. If you are a parent or legal
            guardian of child whose information was provided to us without your
            consent, do not hesitate to contact us so we can take immediate
            actions in order to stop processing and delete all of such child’s
            data collected by us.
          </Text>
        </TextSection>
      </TextSection>
    </TextWrapper>
    <Footer />
  </Layout>
)

export default PrivacyPolicy

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
